import numeral from 'numeral';

export const currencyFormatter = (number: any) => {
  if (Number(number) > 999999) {
    return numeral(number).format('0,0[.]00a');
  }
  return numeral(number).format('0,0[.]00[00000]');
};

export const currencyCode: Record<string, string> = {
  AFN: 'AF',
  ALL: 'AL',
  DZD: 'DZ',
  EUR: 'EU',
  AOA: 'AO',
  XCD: 'KN',
  ARS: 'AR',
  AMD: 'AM',
  ANG: 'SX',
  AUD: 'AU',
  AZN: 'AZ',
  BSD: 'BS',
  BHD: 'BH',
  BDT: 'BD',
  BBD: 'BB',
  BYR: 'BY',
  BZD: 'BZ',
  XOF: 'TG',
  BMD: 'BM',
  INR: 'IN',
  BOB: 'BO',
  BAM: 'BA',
  BWP: 'BW',
  BRL: 'BR',
  USD: 'US',
  BGN: 'BG',
  BIF: 'BI',
  KHR: 'KH',
  XAF: 'GA',
  CAD: 'CA',
  CVE: 'CV',
  KYD: 'KY',
  CLP: 'CL',
  CNY: 'CN',
  COP: 'CO',
  KMF: 'KM',
  CDF: 'CD',
  NZD: 'NZ',
  CRC: 'CR',
  HRK: 'HR',
  CUP: 'CU',
  CYP: 'CY',
  CZK: 'CZ',
  DKK: 'DK',
  DJF: 'DJ',
  DOP: 'DO',
  ECS: 'EC',
  EGP: 'EG',
  SVC: 'SV',
  ETB: 'ET',
  EEK: 'EE',
  FKP: 'FK',
  FJD: 'FJ',
  XPF: 'PF',
  GMD: 'GM',
  GEL: 'GE',
  GHS: 'GH',
  GIP: 'GI',
  GTQ: 'GT',
  GGP: 'GG',
  GNF: 'GN',
  HTG: 'HT',
  HNL: 'HN',
  HKD: 'HK',
  HUF: 'HU',
  ISK: 'IS',
  IDR: 'ID',
  IRR: 'IR',
  IQD: 'IQ',
  GBP: 'GB',
  ILS: 'IL',
  JMD: 'JM',
  JPY: 'JP',
  JOD: 'PS',
  KZT: 'KZ',
  KES: 'KE',
  KPW: 'KP',
  KRW: 'KR',
  KWD: 'KW',
  KGS: 'KG',
  LAK: 'LA',
  LVL: 'LV',
  LBP: 'LB',
  LSL: 'LS',
  LRD: 'LR',
  LYD: 'LY',
  CHF: 'CH',
  LTL: 'LT',
  MOP: 'MO',
  MKD: 'MK',
  MGA: 'MG',
  MWK: 'MW',
  MYR: 'MY',
  MVR: 'MV',
  MTL: 'MT',
  MRO: 'MR',
  MUR: 'MU',
  MXN: 'MX',
  MDL: 'MD',
  MNT: 'MN',
  MAD: 'MA',
  MZN: 'MZ',
  MMK: 'MM',
  NAD: 'NA',
  NPR: 'NP',
  NIO: 'NI',
  NGN: 'NG',
  NOK: 'NO',
  OMR: 'OM',
  PKR: 'PK',
  PAB: 'PA',
  PGK: 'PG',
  PYG: 'PY',
  PEN: 'PE',
  PHP: 'PH',
  PLN: 'PL',
  QAR: 'QA',
  RON: 'RO',
  RUB: 'RU',
  RWF: 'RW',
  STD: 'ST',
  SAR: 'SA',
  RSD: 'RS',
  SCR: 'SC',
  SLL: 'SL',
  SGD: 'SG',
  SKK: 'SK',
  SBD: 'SB',
  SOS: 'SO',
  ZAR: 'ZA',
  SSP: 'SS',
  LKR: 'LK',
  SDG: 'SD',
  SRD: 'SR',
  SZL: 'SZ',
  SEK: 'SE',
  SYP: 'SY',
  TWD: 'TW',
  TJS: 'TJ',
  TZS: 'TZ',
  THB: 'TH',
  TOP: 'TO',
  TTD: 'TT',
  TND: 'TN',
  TRY: 'TR',
  TMT: 'TM',
  UGX: 'UG',
  UAH: 'UA',
  AED: 'AE',
  UYU: 'UY',
  UZS: 'UZ',
  VUV: 'VU',
  VEF: 'VE',
  VND: 'VN',
  YER: 'YE',
  ZMK: 'ZM',
  ZWD: 'ZW',
};

export const priceByCurrency = (price: number, rate: number) => price * rate;

export const coinName: Record<string, string> = {
  BTC: 'Bitcoin',
  USDT: 'Tether',
  ETH: 'Ethereum',
  ETHW: 'ETHPoW',
  ETHS: 'ETHPoS',
  LTC: 'Litecoin',
  XRP: 'Ripple',
  ADA: 'Cardano',
  OMG: 'OmiseGO',
  MKR: 'MakerDAO',
  UNI: 'Uniswap',
  SOL: 'Solana',
  MATIC: 'Polygon',
  POL: 'Polygon',
  XCR: 'Xenea',
  BCH: 'Bitcoin Cash',
  XLM: 'Stellar Lumens',
  XTZ: 'Tezos',
  AVAX: 'Avalanche',
  IOST: 'IOST',
  MANA: 'Decentraland',
  SAND: 'The Sandbox',
  DAI: 'DAI',
  BUSD: 'Binance USD',
  USDC: 'USD Coin',
  USD: '(USDC, TUSD, DAI)',
  ATOM: 'Cosmos',
  DOGE: 'Dogecoin',
  SHIB: 'Shiba Inu',
  FTM: 'Fantom',
  DOT: 'Polkadot',
  IOTX: 'IoTeX',
  NEAR: 'NEAR Protocol',
  LUNC: 'Terra Classic',
  LUNA: 'Terra',
  YGG: 'Yield Guild Games',
  KP3R: 'Keep3rV1',
  SWEAT: 'Sweat Economy',
  XENE: 'Xenea',
  S: 'Sonic',
};

export const PAIR_WARNING = ['LUNC_USDT', 'LUNC_USD'];
